<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Días festivos'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn 
                                    color="#004BAF" 
                                    class="btnAdd" 
                                    right 
                                    rounded 
                                    @click="abrirModal('add')"  
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" style="margin-top: 18px;" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col lg="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicial"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            </v-col>

                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="sucursales"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Sucursal"
                                    persistent-hint
                                    v-model="sucursal_value_search"
                                    no-data-text="Datos no disponibles"
                                ></v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td v-if="rol == 'root' || rol == 'admin'">
                                    <div class="tblPrincipal">
                                        {{ item.empresa.nombre }}
                                    </div>
                                    <div class="tblSecundario">
                                        {{ item.sucursal.nombre }}
                                    </div>
                                </td>
                                <td v-if="rol == 'admin-empresa'">
                                    <div class="tblPrincipal">
                                        {{ item.sucursal.nombre}}
                                    </div>
                                </td>
                                
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblPrincipal">{{ dateFormat(item.fecha) }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col xs="12" sm="8" md="8" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined 
                                                        label="Nombre" 
                                                        class="pa-0 ma-0" 
                                                        v-model="diaFestivo.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col xs="8" sm="4" md="4" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="diaFestivo.fecha"
                                                    label="Fecha"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="Fecha"
                                                />
                                            </v-col>
                                            <template v-if="rol == 'root'">
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Cliente"
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors" 
                                                            no-data-text="Datos no disponibles"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <v-col
                                                v-if="rol == 'root' || rol == 'admin'" 
                                                cols="12" xs="12" sm="12" md="12" 
                                                class="py-0"
                                            >
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                        no-data-text="Datos no disponibles"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>

                                            <!-- <template v-if="accion == 'update'">
                                                <v-col 
                                                    v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'"
                                                    cols="12" xs="12" sm="12" md="12" 
                                                    class="py-0"
                                                >
                                                    <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Sucursal"
                                                            :items="sucursales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="sucursal_value"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors" 
                                                            no-data-text="Datos no disponibles"
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                            </template> -->

                                            <template>
                                                <v-col 
                                                    v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'"
                                                    cols="12" xs="12" sm="12" md="12" 
                                                    class="py-0"
                                                >
                                                    <VuetifyMultipleAutocomplete
                                                        outlined
                                                        :items="sucursales"
                                                        :valorDefecto="sucursalesSeleccionadas"
                                                        item_text="nombre"
                                                        @dataMultiple="(data)=> sucursalesSeleccionadas = data"
                                                        label="Sucursales"
                                                        ref="multSucursal"
                                                        :rules="'required'"
                                                    />
                                                </v-col>
                                            </template>

                                            <template>
                                                <v-col xs="12" sm="12" md="12" class="py-0">
                                                    <VuetifyMultipleAutocomplete
                                                        outlined
                                                        :items="departamentos"
                                                        :valorDefecto="departamentos_value"
                                                        item_text="nombre"
                                                        @dataMultiple="(data)=> departamentos_value = data"
                                                        label="Departamentos"
                                                        ref="multDepto"
                                                    />
                                                </v-col>
                                            </template>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-textarea
                                                        outlined
                                                        rows="2"
                                                        label="Descripción"
                                                        v-model="diaFestivo.descripcion"
                                                        :error-messages="errors"
                                                    ></v-textarea>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/DiasFestivos';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import sucursalApi from '@/api/sucursales';
import departamentoApi from "@/api/departamentos";
import festivoApi from "../api/dias_festivos";
import Notify from '@/plugins/notify';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
        VuetifyMultipleAutocomplete,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Descripcion',
                    name: 'descripcion',
                    filterable: false
                },
                {
                    label: 'Fecha',
                    name: 'fecha',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            nombre: null,
            fecha_inicio: null,
            fecha_final: null,
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            diaFestivo          : {
                id              : 0,
                nombre          : "",
                fecha           : "",
                descripcion     : "",
                cliente_id      : null,
                empresa_id      : null,
                sucursales      : [],
                departamentos   : [],
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            departamentos   : [],
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            departamentos_value: [],
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            menu_inicio     : false,
            menu_inicio_C   : false,
            menu_inicio_fin : false,
            menu_final_C      : false,
            fecha_final     : null,
            fecha_inicio    : null,
            departamentos_id: false,
            menu            : false,
            cliente_value_search : null,
            empresa_value_search : null,
            sucursal_value_search: null,
            isLoadingModal  : true,
            sucursalesSeleccionadas: [],
            isUpdate: false,

        }
    },
    watch: {

        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        empresa_value: function(val){
            
            if(this.isUpdate){
                this.sucursalesSeleccionadas = [];
            }
            this.cambiaEmpresa(val);
        },
        sucursal_value: function(val) {
            //this.departamentos_value = [];
            this.getDepartamentos(val);
        },
        sucursalesSeleccionadas:{
            deep:true,  
            handler(val, oldVal){

                if(this.isUpdate){
                    this.departamentos_value = [];
                }
                this.cambiaSucursales(val);
            }
        },
    },
    methods: {
        PaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.isLoading = true;
        },
        async abrirModal(accion, data = null){
            this.accion     = accion;
            await this.resetValue();
            this.isLoadingModal = true;

            if(accion == "add"){
                this.tituloModal = "Registrar día festivo";
                this.imagen = "/static/modal/diaFestivoCreate.svg";
                if(this.rol == 'admin-sucursal'){
                    this.sucursalesSeleccionadas.push(this.datosLogin.sucursal_id)
                }
                this.isLoadingModal = false;
                this.isUpdate = true
            } else {
                this.tituloModal = "Actualizar día festivo";
                this.imagen = "/static/modal/diaFestivoUpdate.svg";
                this.isUpdate = false
                await festivoApi.getDia(data.id).then((response) => {
                    this.diaFestivo.id              = response.id;
                    this.diaFestivo.nombre          = response.nombre;
                    this.diaFestivo.fecha           = response.fecha;
                    this.diaFestivo.descripcion     = response.descripcion;
                    this.diaFestivo.cliente_id      = response.cliente_id;
                    this.diaFestivo.empresa_id      = response.empresa_id;
                    this.sucursalesSeleccionadas    = response.sucursales;
                    this.departamentos_value        = response.departamentos;
                    
                    setTimeout(()=>{
                        this.isUpdate = true
                        this.isLoadingModal = false;
                    },3000);
                })
                .catch((err) => {
                    this.respuesta_error(err);
                });
                setTimeout(()=>this.$refs.Fecha.setDate(data.fecha),200);

            }

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            } else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.cambiaEmpresa(this.datosLogin.empresa_id);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                //this.getDepartamentos();
            }
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog = false;
            this.isSaving = false;
            this.cliente_value = null;
            this.empresa_value = null;
            this.sucursal_value = null;
            this.departamentos_value = null;
            this.resetValue();
        },
        async resetValue() {
            this.diaFestivo     = {
                id              : 0,
                nombre          : "",
                fecha           : "",
                descripcion     : "",
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamentos   : [],
            };
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            if(this.$refs.Fecha != undefined || this.$refs.Fecha != null){
                this.$refs.Fecha.resetDate();
            }
            
            

            this.$nextTick(() => { 
                this.cliente_value              = null;
                this.empresa_value              = null;
                this.sucursal_value             = null;
                this.sucursal_value_search      = null;
                this.clientes                   = [];
                this.empresas                   = [];
                this.sucursales                 = [];
                this.departamentos_value        = [];
                this.departamentos              = [];
                this.departamentos_id           = false;
                this.nombre                     = null;
                this.fecha_inicio               = null;
                this.fecha_final                = null;
                this.sucursalesSeleccionadas    = [];
                this.isUpdate                   = true;
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },
        async loadModalData() {
            await this.resetValue();
            if (this.rol == "root") {
                this.getClientes();
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value_search = null;
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = null;
                this.sucursal_value_search = null;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value_search = null;
            } else if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value_search = this.datosLogin.sucursal_id;
            }
        },
        setFilters() {
            this.filters = {AND:[]};
            let nombre = this.nombre;
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let cliente = this.cliente_value;;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value_search;
            let departamento;
            let dates = 0;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 
            else if (this.rol == "admin-sucursal") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            } 
            else if (this.rol == "admin-depto") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
                departamento = this.datosLogin.departamento_id;
            }
            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                this.filters.AND.push({column:'FECHA',operator: 'GTE' , value:fecha_inicio });
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                this.filters.AND.push({column:'FECHA',operator: 'LTE' , value:fecha_final });
            }

            if(this.$apollo.queries.dias_festivos.skip){
                this.$apollo.queries.dias_festivos.skip = false;
            }
     
            this.isLoading = true;

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }
            if(sucursal != null && sucursal != undefined && sucursal != ""){
                this.filters.AND.push({column:'SUCURSAL_ID', value:sucursal});
            }

            this.paginationData.numberPage = 1;
            this.$apollo.queries.dias_festivos.refetch();
            this.resetValue();
        },
        
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false }
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
                if (this.diaFestivo.cliente_id != null) {
                    this.cliente_value = this.diaFestivo.cliente_id;
                }
            })
            .catch(error => {
                this.resetValue();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        async cambiaCliente(id, cliente = null) {
            if(id == null){
                return;
            }
            let parametros = { activo: true, paginate: false, cliente_id: id };
            await empresaApi.getEmpresas(parametros).then((response)=>{
                this.empresas = response.data;
                if (this.diaFestivo.empresa_id != null) {
                    this.empresa_value = this.diaFestivo.empresa_id;
                }
            })
            .catch(error => {
                console.log(error);
                Notify.ErrorToast("Ocurrio un error al cargar las empresas.");
            });
        },
        async cambiaEmpresa(id, sucursal = null) {
            if(id == null){
                return;
            }
            
            let parametros = { activo:true, paginate: false, empresa_id: id }
            await sucursalApi.getSucursales(parametros).then((response)=>{
                this.sucursales = response.data;
                
            })
            .catch(error => {
                this.resetValue();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        async getDepartamentos(val = null) {
            let arraySucursal = []
            let cliente_value = null;
            let empresa_value = null;
            let sucursal_value = null;
            if(val == null){
                this.departamentos = [];
                return;
            }

            if(this.rol == "root"){
                cliente_value = this.cliente_value;
                empresa_value = this.empresa_value;
            }
            else if (this.rol == "admin") {
                cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                cliente_value = this.datosLogin.cliente_id;
                empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                cliente_value = this.datosLogin.cliente_id;
                empresa_value = this.datosLogin.empresa_id;
                sucursal_value = this.datosLogin.sucursal_id;
            }
            arraySucursal = val
            let param = {}

            param = {
                activo          : true,
                paginate        : false,
                cliente_id      : cliente_value,
                empresa_id      : empresa_value,
                sucursal_id     : sucursal_value,
                
            };

            await departamentoApi.getDepartamentos(param).then((response) => {
                this.departamentos = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
        },
        cambiaSucursales(items) {
            if(items.length == 0){
                this.departamentos = [];
                return;
            }
            
            let parametros = { activo: true, paginate: false, arraySucursal: JSON.stringify(items) };
            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
                this.departamentos.map(depto => depto.nombre += " - "+depto.nombre_sucursal);
            })
            .catch(error => {
                Notify.ErrorToast(error);
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let depto = [];
                this.diaFestivo.cliente_id      = this.cliente_value;
                this.diaFestivo.empresa_id      = this.empresa_value;
                this.diaFestivo.sucursal_id     = this.sucursal_value;
                
                this.isLoading = true;

                this.diaFestivo.sucursales =  this.sucursalesSeleccionadas;
                this.diaFestivo.departamentos = this.departamentos_value;

                if(this.accion === "add") {

                    festivoApi.addDia(this.diaFestivo).then((response) => {
                        Notify.Success("Operación exitosa", "Se ha registrado el día festivo satisfactoriamente.");
                        this.$apollo.queries.dias_festivos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {

                    festivoApi.updateDia(this.diaFestivo).then((response) => {
                        Notify.Success("Operación exitosa", "Se ha actualizado el día festivo satisfactoriamente.");
                        this.$apollo.queries.dias_festivos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el día festivo " + data.nombre + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    festivoApi.deleteDia(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El día festivo ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.dias_festivos.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        Cambio_depto(){
            this.departamentos_id = true;
        },
        respuesta_error(err) {
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        cargaInicial(){
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.filters = {AND:[]};


            if(this.rol == 'root'){
                this.columns = [
                    { label: "Empresa", name: "empresa", filterable: false },
                    { label: "Nombre", name: "nombre", filterable: true },
                    { label: "Descripción", name: "descripcion", filterable: true },
                    { label: "Fecha", name: "fecha", filterable: true },
                    { label: "Opciones", name: "opciones", filterable: false, align: 'center'},
                ]
            }

            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};

                this.columns = [
                    { label: "Empresa", name: "empresa", filterable: false },
                    { label: "Nombre", name: "nombre", filterable: true },
                    { label: "Descripción", name: "descripcion", filterable: true },
                    { label: "Fecha", name: "fecha", filterable: true },
                    { label: "Opciones", name: "opciones", filterable: false, align: 'center'},
                ]
            }
            else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});

                this.columns = [
                    { label: "Sucursal", name: "sucursal", filterable: false },
                    { label: "Nombre", name: "nombre", filterable: true },
                    { label: "Descripción", name: "descripcion", filterable: true },
                    { label: "Fecha", name: "fecha", filterable: true },
                    { label: "Opciones", name: "opciones", filterable: false, align: 'center'},
                ]
            }
            else if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
                this.filters.AND.push({column:'SUCURSAL_ID', value:this.datosLogin.sucursal_id});

                this.columns = [
                    { label: "Nombre", name: "nombre", filterable: true },
                    { label: "Descripción", name: "descripcion", filterable: true },
                    { label: "Fecha", name: "fecha", filterable: true },
                    { label: "Opciones", name: "opciones", filterable: false, align: 'center'},
                ]
            }
            else if (this.rol == "admin-depto"){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
                this.filters.AND.push({column:'SUCURSAL_ID', value:this.datosLogin.sucursal_id});
                this.columns = [
                    { label: "Nombre", name: "nombre", filterable: true },
                    { label: "Descripción", name: "descripcion", filterable: true },
                    { label: "Fecha", name: "fecha", filterable: true },
                    { label: "Opciones", name: "opciones", filterable: false, align: 'center'},
                ]
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.cargaInicial();
    },
    mounted(){
        this.$apollo.queries.dias_festivos.skip = false;
    },
    apollo: {
        dias_festivos: {
            query       : queries.dias_festivosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    whereCondition : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.dias_festivos;   
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .tipoLetra700Azul {
        font-weight: 700;
        letter-spacing: -0.34px;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .color_rojo {
        color: red;
        margin-left: 20px;
    }
</style>
