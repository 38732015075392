import Vue from 'vue';

export default{
    getDias(parametros){
        return Vue.axios.get("dias-festivos/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDia(id){
        return Vue.axios.get("dias-festivos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDia(parametros){
        return Vue.axios.put("dias-festivos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDia(id){
        return Vue.axios.delete("dias-festivos/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addDia(parametros){
        return Vue.axios.post("dias-festivos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}